import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IElement } from '../../../../types';
import { Element } from '../Element';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  value: IElement;
}

interface State {
  collapsed: boolean;
}

export class CollapsibleViewWithTitle extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box
          sx={{
            borderWidth: '1px',
            borderColor: 'transparent',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <Typography>{this.props.value.title}</Typography>
          {this.state.collapsed ? (
            <ArrowDropDownIcon onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))} />
          ) : (
            <ArrowDropUpIcon onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))} />
          )}
        </Box>
        <Box sx={{ marginLeft: 2 }}>
          {!this.state.collapsed && this.props.value.children.map(elem => <Element value={elem} />)}
        </Box>
      </Box>
    );
  }
}
