import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import '../../../../maneStyles.css';

interface Props {
  name: string | null;
  onClick: () => void;
  onHide: () => void;
}

export class Header extends PureComponent<Props> {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <Box className="topicListViewHeaderBox">
          <Box className="flex">
            <ArrowBackIcon className="hover" onClick={this.props.onClick} />
            <Typography className="name colorMain">{this.props.name}</Typography>
          </Box>
          <DeleteIcon className="deleteIcon hover" onClick={this.props.onHide} />
        </Box>
      </StyledEngineProvider>
    );
  }
}
