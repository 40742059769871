import React, { Component } from 'react';
import axios from 'axios';

import { HeaderBar } from '../headerBar';
import { Route, Routes } from 'react-router-dom';
import { UnauthorizedHeaderBar } from '../unauthorizedHeaderBar';
import { Login } from '../login';
import { Forbidden } from '../403';
import { RequireAuth } from '../requireAuth';
import { Chat } from '../chat';
import { Editor } from '../editor';
import { Service } from '../service';
import { getAuthToken } from '../../services';
import { Localization } from '../localization';
// import { makeStyles } from '@mui/styles';

export let axiosInstance = axios.create({
  headers: {
    common: {
      Authorization: `token=${getAuthToken()}`
    }
  }
});

interface Props {}

interface State {}

// const useStyles = makeStyles({ color_style: { backgroundColor: 'red' } });

export class App extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {};
  }
  // @ts-ignore
  // classes = useStyles();
  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <HeaderBar />
            </RequireAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Chat />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/localization"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Localization />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/editor"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Editor />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/editor/service/:id"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Service />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <UnauthorizedHeaderBar />
              <Login />
            </>
          }
        />
        <Route path="/403" element={<Forbidden />} />
      </Routes>
    );
  }
}
