import React, { Component } from 'react';
import { Box, Button, Container, Grid, MenuItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { IElement, IPage } from '../types';
import { Element as EditElement } from './components/edit/Element';
import { Element as RenderElement } from './components/render/Element';
import { Header as EditHeader } from './components/edit/Header';
import { Header as RenderHeader } from './components/render/Header';
import { getBodyByType } from './factory';
import { useParams } from 'react-router-dom';
import env from '../../environment.json';
import { axiosInstance } from '../app';
import { AxiosError } from 'axios';
import { removeAuthToken } from '../../services';

interface Props {
  id: string | undefined;
}

interface State {
  backup: Record<string, null | IPage>;
  value: null | IPage;
  category: Record<number, string>;
  country: string;
  selected: string | null;
  languages: string[];
  language: string;
}

class ServiceConnect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      backup: {},
      value: null,
      category: {},
      country: 'spain',
      selected: null,
      languages: ['ru', 'en'],
      language: 'ru'
    };
  }
  componentDidMount() {
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/service?id=${this.props.id}&language=${this.state.language}`)
      .then(response => {
        this.setState(prev => ({
          value: {
            ...response.data
          },
          backup: {
            ...prev.backup,
            [prev.language]: response.data
          }
        }));
        // @ts-ignore
        return axiosInstance.get(`${env[env.environment].host}/v1/service/list/category?language=ru`);
      })
      .then(response => {
        const category: Record<number, string> = {};
        response.data.forEach(({ id, value }: any) => {
          category[id] = value;
        });
        this.setState(() => ({ category }));
      })
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
    // @ts-ignore
  }

  onSave = () => {
    axiosInstance
      .put(
        // @ts-ignore
        `${env[env.environment].host}/v1/service?id=${this.props.id}&language=${this.state.language}`,
        this.state.value
      )
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  };

  onChangeChildren = (index: number, value: IElement) =>
    // @ts-ignore
    this.setState((prev: State) => {
      if (prev.value) {
        const result: IPage = { ...prev.value };
        if (result.children != null) {
          result.children.splice(index, 1, value);
        }
        return result;
      }
      return { value: null };
    });

  onChangeField = (field: string, value: string) =>
    this.setState(prev => ({
      value:
        prev.value != null
          ? {
              ...prev.value,
              [field]: value
            }
          : null
    }));

  onClick = (id: string) => {
    this.setState(() => ({ selected: id }));
  };

  onAdd = (type: string) => {
    if (this.state.value != null) {
      const obj = getBodyByType(type);
      const result = { ...this.state.value };
      if (this.state.selected != null) {
        let inserted = false;
        for (let i = 0; i < result.children.length; ++i) {
          if (result.children[i].id === this.state.selected) {
            result.children.splice(i + 1, 0, ...obj);
            break;
          } else {
            for (let j = 0; j < result.children[i].children.length; ++j) {
              if (result.children[i].children[j].id === this.state.selected) {
                inserted = true;
                const preResult = { ...result.children[i] };
                preResult.children.splice(j + 1, 0, ...obj);
                result.children.splice(i, 1, preResult);
                break;
              }
            }
          }
          if (inserted) {
            break;
          }
        }
      } else {
        result.children.push(...obj);
      }
      this.setState(() => ({ value: result, selected: null }));
    }
  };

  onDelete = () => {
    if (this.state.value != null) {
      const result = { ...this.state.value };
      let inserted = false;
      for (let i = 0; i < result.children.length; ++i) {
        if (result.children[i].id === this.state.selected) {
          result.children.splice(i, 1);
          break;
        } else {
          for (let j = 0; j < result.children[i].children.length; ++j) {
            if (result.children[i].children[j].id === this.state.selected) {
              inserted = true;
              const preResult = { ...result.children[i] };
              preResult.children.splice(j, 1);
              result.children.splice(i, 1, preResult);
              break;
            }
          }
        }
        if (inserted) {
          break;
        }
      }
      this.setState(() => ({ value: result, selected: null }));
    }
  };

  onChangeCategory = (id: string) =>
    this.setState(prev => ({
      value:
        prev.value != null
          ? { ...prev.value, categoryID: parseInt(id, 10), category: prev.category[parseInt(id, 10)] }
          : null
    }));

  onChangeLanguage = (value: string) => {
    this.setState({ language: value });
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/service?id=${this.props.id}&language=${value}`)
      .then(response => {
        if (response.data != null) {
          this.setState(prev => ({
            value: {
              ...response.data
            },
            backup: {
              ...prev.backup,
              [prev.language]: response.data
            }
          }));
        } else {
          this.setState(prev => ({
            value: prev.backup['ru']
          }));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { value, selected } = this.state;
    if (value == null) {
      return null;
    }
    return (
      <Container
        style={{
          padding: 0
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              sx={{ p: 0, width: '90ch' }}
              onChange={event => this.onChangeField('name', event.target.value)}
              value={this.state.value?.name}
              fullWidth
              multiline
              id="title"
              variant="standard"
              placeholder="Body"
            />
            <TextField
              sx={{ p: 0, ml: 2, width: '15ch' }}
              disabled={true}
              value={this.state.value?.topic}
              fullWidth
              multiline
              id="title"
              variant="standard"
              placeholder="Body"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box>
              <TextField
                onChange={event => this.onChangeCategory(event.target.value)}
                value={this.state.value?.categoryID}
                sx={{ width: '60ch', mr: 2 }}
                size="small"
                id="outlined-select-currency"
                select
                variant="standard">
                {Object.keys(this.state.category).map((id: string) => (
                  <MenuItem value={id}>{this.state.category[parseInt(id, 10)]}</MenuItem>
                ))}
              </TextField>
              <TextField
                disabled={true}
                sx={{ width: '28ch' }}
                margin="none"
                size="small"
                id="outlined-select-currency"
                select
                variant="standard"
                value={this.state.country}>
                <MenuItem value={this.state.country}>{this.state.country}</MenuItem>
              </TextField>
              <TextField
                sx={{ width: '10ch', ml: '20px' }}
                margin="none"
                size="small"
                value={this.state.language}
                select
                variant="standard"
                onChange={event => this.onChangeLanguage(event.target.value)}>
                {this.state.languages.map(language => (
                  <MenuItem value={language} key={language}>
                    {language}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Button sx={{ mr: 1 }} variant="contained" onClick={() => this.onSave()}>
              Сохранить
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, alignItems: 'center' }}>
            <DeleteIcon
              onClick={this.onDelete}
              sx={{
                color: 'red',
                opacity: 0.7,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            />
            <Button onClick={() => this.onAdd('CostBox')}>Cost</Button>
            <Button onClick={() => this.onAdd('CollapsibleViewWithTitle')}>Collapse</Button>
            <Button onClick={() => this.onAdd('Base')}>Text</Button>
            <Button onClick={() => this.onAdd('AttentionBoxWithText')}>Attention</Button>
            <Button onClick={() => this.onAdd('InfoBoxWithText')}>Info</Button>
            <Button onClick={() => this.onAdd('DocBox')}>DocBox</Button>
            <Button onClick={() => this.onAdd('DocBoxWithText')}>DocBox&Text</Button>
            <Button onClick={() => this.onAdd('TextWithPlot')}>Plot&Text</Button>
            <Button onClick={() => this.onAdd('TextWithIndex')}>Index&Text</Button>
            <Button onClick={() => this.onAdd('TextWithIndent')}>Indent&Text</Button>
            <Button onClick={() => this.onAdd('Spacing16')}>SP16</Button>
          </Box>
        </Box>
        <Grid container sx={{ marginTop: 1 }}>
          <Grid item xs={6} md={6} lg={6}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                paddingTop: 1,
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderTopColor: '#D7D7D7',
                borderRightWidth: '1px',
                borderRightStyle: 'solid',
                borderRightColor: '#D7D7D7'
              }}>
              <EditHeader value={value.header} onChange={value => this.onChangeField('header', value)} />
              {value.children.map((elem, index) => (
                <EditElement
                  selected={selected}
                  onClick={this.onClick}
                  value={elem}
                  onChange={value => this.onChangeChildren(index, value)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                paddingTop: 1,
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderTopColor: '#D7D7D7'
              }}>
              <RenderHeader value={value.header} />
              {value.children.map(elem => (
                <RenderElement value={elem} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export const Service = () => {
  const { id } = useParams();
  return <ServiceConnect id={id} />;
};
