import React, { PureComponent } from 'react';
import { CollapsibleViewWithTitle } from '../CollapsibleViewWithTitle';
import { Spacing16 } from '../Spacing16';
import { IElement } from '../../../../types';
import { CostBox } from '../CostBox';
import { Base } from '../Base';
import { DocBox } from '../DocBox';
import { DocBoxWithText } from '../DocBoxWithText';
import { InfoBoxWithText } from '../InfoBoxWithText';
import { AttentionBoxWithText } from '../AttentionBoxWithText';
import { TextWithPlot } from '../TextWithPlot';
import { TextWithIndex } from '../TextWithIndex';
import { TextWithIndent } from '../TextWithIndent';
import { Box } from '@mui/material';

interface Props {
  value: IElement;
}

export class Element extends PureComponent<Props> {
  renderBody() {
    const { value } = this.props;
    switch (value.type) {
      case 'CollapsibleViewWithTitle': {
        return <CollapsibleViewWithTitle value={value} />;
      }
      case 'CostBox': {
        return <CostBox value={value} />;
      }
      case 'Base': {
        return <Base value={value} />;
      }
      case 'DocBox': {
        return <DocBox value={value} />;
      }
      case 'DocBoxWithText': {
        return <DocBoxWithText value={value} />;
      }
      case 'InfoBoxWithText': {
        if (value.attention) {
          return <AttentionBoxWithText value={value} />;
        }
        return <InfoBoxWithText value={value} />;
      }
      case 'TextWithPlot': {
        return <TextWithPlot value={value} />;
      }
      case 'TextWithIndex': {
        return <TextWithIndex value={value} />;
      }
      case 'TextWithIndent': {
        return <TextWithIndent value={value} />;
      }
      case 'Spacing16': {
        return <Spacing16 />;
      }
      default: {
        return null;
      }
    }
  }

  render() {
    return <Box sx={{ borderWidth: '1px', borderColor: 'transparent', borderStyle: 'solid' }}>{this.renderBody()}</Box>;
  }
}
