import React, { Component } from 'react';
import { Alert, Button, Container, Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { io } from 'socket.io-client';
import { DateTime } from 'luxon';
import has from 'lodash/has';

import { IMessage, Message } from '../types';
import { ClientListView } from './components/ClientListView';
import { MessageListView } from './components/MessageListView';
import { cAvatarColorList } from './components/Avatar';
import { getAuthToken, removeAuthToken } from '../../services';
import env from '../../environment.json';
import './index.css';

export const cTopicAliasToName: Record<string, string> = {
  'spain-common': 'Первые шаги в Испании',
  'spain-06': 'Открытие индивидуального банковского счёта по доверенности для граждан РФ',
  'spain-07':
    'Открытие индивидуального банковского счёта при личном присутствии для граждан бывших республик СССР, кроме РФ и РБ',
  'spain-08':
    'Открытие индивидуального банковского счёта при личном присутствии для граждан Российской Федерации и Республики Беларусь',
  'spain-09':
    'Открытие семейного банковского счёта при личном присутствии для граждан бывших республик СССР, кроме РФ и РБ',
  'spain-010':
    'Открытие семейного банковского счёта при личном присутствии для граждан Российской Федерации и Республики Беларусь',
  'spain-10': 'Оформление декларации о въезде в страну',
  'spain-11': 'Оформление декларации о ввозе наличных средств на территорию Испании',
  'spain-12': 'Оформление идентификационного номера иностранца',
  'spain-13': 'Оформление идентификационного номера иностранца ОДНОВРЕМЕННО для двоих',
  'spain-14': 'Присяжные переводы',
  'spain-15': 'Прописка',
  'spain-16': 'Получение CITA',
  'spain-20': 'Подбор риелтора',
  'spain-30': 'Создание Индивидуального Предпринимательства',
  'spain-31': 'Создание Общества с ограниченной ответственностью',
  'spain-32': 'Финансовый консультант',
  'spain-33': 'Дистанционное изготовление ЭЦП',
  'spain-34': 'Юридический консультант',
  'spain-40': 'Языковые курсы',
  'spain-41': 'Омологация дипломов и аттестатов в Испании',
  'spain-50': 'Подбор школы в Испании',
  'spain-51': 'Подбор детского сада в Испании',
  'spain-52': 'Получения свидетельства о рождении ребёнка в Испании',
  'spain-60': 'Перевозка автомобиля',
  'spain-61': 'Перерегистрация автомобиля в Испании',
  'spain-62': 'Прокат скутеров и мотоциклов',
  'spain-63': 'Прокат автомобилей',
  'spain-64': 'Перевозка грузов и личных вещей из России',
  'spain-70': 'Услуги переводчика',
  'spain-71': 'Подбор страхового агента',
  'france-common': 'Первые шаги во Франции',
  'france-00': 'Открытие индивидуального банковского счёта при личном присутствии',
  'france-01': 'Открытие семейного банковского счёта при личном присутствии',
  'france-10': 'Оформление всех видов страхования',
  'france-11': 'Оформление Французского налогового номера',
  'france-12': 'Присяжные переводы на французский язык',
  'france-13': 'Слоты на прописку, в полицию',
  'france-14': 'Оформление деклараций',
  'france-15': 'Апостиль',
  'france-20': 'Замена водительских прав',
  'france-30': 'Запись к врачу и сопровождение в медучреждения',
  'france-31': 'Оформление Carte Vitale',
  'france-40': 'Сопровождение в различные учреждения Франции',
  'france-41': 'Сопровождение и помощь переводчика с различными услугами',
  'france-50': 'Оформление студенческой резиденции',
  'france-60': 'Подбор детского садика',
  'france-61': 'Подбор школы',
  'france-62': 'Подбор университета'
};

// @ts-ignore
const socket = io(`${env[env.environment].socket}`, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

socket.on('connect', () => {
  console.log('connect:', socket.connected);
});

socket.on('connect_error', error => {
  console.log('connect_error:', error);
  removeAuthToken();
  window.location.reload();
});

socket.io.on('error', error => {
  console.log(error);
});

export interface IChatMessage {
  id: number;
  root: boolean;
  topic: string;
  author: string;
  colorIndex: number;
  value: string;
  time: string;
}

export interface IChatTopic {
  id: string;
  name: string;
  data: IChatMessage[];
}

export interface IClient {
  id: string;
  pin: number;
  deviceID: string;
  userID: number | null;
  colorIndex: number;
  name: string | null;
  lastMessage: IChatMessage;
  topicList: Record<string, IChatTopic>;
}

interface Props {}

interface State {
  showAlert: boolean;
  requestHideId: number | null;
  selectedClient: number | null;
  selectedTopic: string | null;
  clientList: IClient[];
}

export class Chat extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      requestHideId: null,
      showAlert: false,
      clientList: [],
      selectedClient: null,
      selectedTopic: null
    };
  }

  componentDidMount() {
    if (!has(socket.auth, 'manager')) {
      const token = getAuthToken();
      if (token != null) {
        socket.auth = { manager: getAuthToken() };
        socket.connect();
      }
      socket.on('history', data => this.onParseHistory(data));
      socket.on('message', data => this.onParseMessage(data));
    }
  }

  onParseHistory = (data: IMessage[]) => {
    const clientList: IClient[] = [];
    const idToIndex: Record<string, number> = {};
    data
      .sort((a, b) => {
        if (a.pin !== b.pin) {
          return a.pin < b.pin ? 1 : -1;
        }
        return DateTime.fromISO(a.created).toMillis() < DateTime.fromISO(b.created).toMillis() ? 1 : -1;
      })
      .forEach(obj => {
        const value = obj.value.trim();
        const topic = obj.topic.trim();
        const dateTime = DateTime.fromISO(obj.created);

        let id = this.parseUserID(obj);
        if (id != null && id.trim().length > 0 && value.length > 0 && topic.length > 0) {
          id = id.trim();
          let index = 0;
          if (!has(idToIndex, id)) {
            idToIndex[id] = clientList.length;
            clientList.push(this.parseUser(obj, id, dateTime));
          }
          index = idToIndex[id];
          if (obj.username != null && obj.username.trim().length > 0) {
            clientList[index].name = obj.username.trim();
          } else if (obj.userIdFrom !== -1 && obj.nickname.trim().length > 0) {
            clientList[index].name = obj.nickname.trim();
          }
          if (topic.length > 0) {
            if (!has(clientList[index].topicList, topic)) {
              clientList[index].topicList[topic] = {
                id: topic,
                name: topic,
                data: []
              };
            }
            clientList[index].topicList[topic].data.unshift(
              this.parseMessage(obj, clientList[index].colorIndex, dateTime)
            );
          }
        }
      });
    this.setState(() => ({
      clientList
    }));
  };

  parseUserID = (obj: IMessage): string =>
    obj.userIdFrom !== -1
      ? obj.userIdFrom != null
        ? `userID-${obj.userIdFrom}`
        : 'deviceID-' + obj.deviceIdFrom
      : obj.userIdTo != null
      ? `userID-${obj.userIdTo}`
      : 'deviceID-' + obj.deviceIdTo;

  parseUser = (obj: IMessage, id: string, dateTime: DateTime): IClient => {
    let colorIndex = 0;
    for (let i = 0; i < id.length; ++i) {
      colorIndex += id.charCodeAt(i);
    }
    colorIndex %= cAvatarColorList.length;
    return {
      id,
      pin: obj.pin,
      deviceID: obj.deviceIdFrom !== 'root' ? obj.deviceIdFrom : obj.deviceIdTo,
      userID: obj.userIdFrom !== -1 ? obj.userIdFrom : obj.userIdTo,
      colorIndex,
      name: 'noname',
      topicList: {},
      lastMessage: this.parseMessage(obj, colorIndex, dateTime)
    };
  };

  parseMessage = (obj: IMessage, colorIndex: number, dateTime: DateTime): IChatMessage => ({
    id: obj.id,
    root: obj.userIdFrom === -1,
    topic: obj.topic,
    author: obj.userIdFrom === -1 ? 'Вы' : obj.nickname.trim().length > 0 ? obj.nickname.trim() : 'Unknown',
    colorIndex,
    value: obj.value,
    time:
      dateTime.toISOWeekDate() === DateTime.now().toISOWeekDate()
        ? dateTime.toFormat('HH:mm')
        : dateTime.toFormat('dd.MM.yyyy')
  });

  onParseMessage = (obj: IMessage) => {
    const dateTime = DateTime.fromISO(obj.created);
    const id = this.parseUserID(obj);
    const client = this.parseUser(obj, id, dateTime);
    client.topicList = {
      [obj.topic]: {
        id: obj.topic,
        name: obj.topic,
        data: [client.lastMessage]
      }
    };
    let index = -1;
    for (let i = 0; i < this.state.clientList.length; ++i) {
      if (this.state.clientList[i].id === id) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      const clientList = [...this.state.clientList];
      let currentClient = clientList[index];
      client.lastMessage.colorIndex = currentClient.colorIndex;
      clientList.splice(index, 1, {
        ...currentClient,
        lastMessage: client.lastMessage,
        topicList: {
          ...currentClient.topicList,
          [client.lastMessage.topic]: has(currentClient.topicList, client.lastMessage.topic)
            ? {
                ...currentClient.topicList[client.lastMessage.topic],
                data: [...currentClient.topicList[client.lastMessage.topic].data, client.lastMessage]
              }
            : {
                ...client.topicList[client.lastMessage.topic]
              }
        }
      });
      this.setState(() => ({ clientList }));
    } else {
      this.setState(prev => ({ clientList: [client, ...prev.clientList] }));
    }
  };

  onSend = (value: string) => {
    const { selectedClient, selectedTopic, clientList } = this.state;
    if (selectedClient != null && selectedTopic != null) {
      const msg: IMessage = new Message(
        DateTime.now().toMillis(),
        0,
        'root',
        -1,
        'root',
        clientList[selectedClient].userID,
        clientList[selectedClient].deviceID,
        clientList[selectedClient].topicList[selectedTopic].id,
        value,
        DateTime.now().toISO(),
        DateTime.now().toISO(),
        null,
        0,
        ''
      );
      socket.emit('message', clientList[selectedClient].id, msg, (response: boolean) => {
        if (response && selectedClient != null && selectedTopic != null) {
          const message: IChatMessage = {
            id: msg.id,
            root: true,
            topic: clientList[selectedClient].topicList[selectedTopic].name,
            author: 'Вы',
            colorIndex: clientList[selectedClient].colorIndex,
            value: msg.value,
            time: DateTime.fromISO(msg.created).toFormat('HH:mm:ss')
          };
          const currentClient = clientList[selectedClient];
          const newClientList = [...clientList];
          newClientList.splice(selectedClient, 1, {
            ...currentClient,
            lastMessage: message,
            topicList: {
              ...currentClient.topicList,
              [selectedTopic]: {
                ...currentClient.topicList[selectedTopic],
                data: [...currentClient.topicList[selectedTopic].data, message]
              }
            }
          });
          this.setState(() => ({ clientList: newClientList }));
        }
      });
    }
  };

  onChangeClient = (index: number) => this.setState(() => ({ selectedClient: index, selectedTopic: null }));

  onResetClient = () => this.setState(() => ({ selectedClient: null, selectedTopic: null }));

  onChangeTopic = (id: string) => this.setState(() => ({ selectedTopic: id }));

  onHideClient = (index: number) => this.setState(() => ({ showAlert: true, requestHideId: index }));

  onCommitHideClient = () => {
    if (this.state.requestHideId != null) {
      socket.emit('hide', this.state.clientList[this.state.requestHideId].deviceID);
      const newClientList = [...this.state.clientList];
      newClientList.splice(this.state.requestHideId, 1);
      this.setState(() => ({
        showAlert: false,
        requestHideId: null,
        clientList: newClientList,
        selectedClient: null,
        selectedTopic: null
      }));
    }
  };

  onPinClient = (userID: number | null) => {
    if (userID != null) {
      socket.emit('pin', userID, (error: Error, success: boolean) => {
        console.log(error, ':', success);
        if (error == null) {
          socket.disconnect();
          socket.connect();
        }
      });
    }
  };

  onUnPinClient = (userID: number | null) => {
    if (userID != null) {
      socket.emit('unpin', userID, (error: Error, success: boolean) => {
        console.log(error, ':', success);
        if (error == null) {
          socket.disconnect();
          socket.connect();
        }
      });
    }
  };

  render() {
    const { clientList, selectedClient, selectedTopic, showAlert } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <Container maxWidth={false} className="chatContainer">
          {showAlert && (
            <Alert
              variant="filled"
              severity="error"
              action={
                <>
                  <Button color="inherit" size="small" onClick={this.onCommitHideClient}>
                    OK
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => this.setState(() => ({ showAlert: false, requestHideId: null }))}>
                    Cancel
                  </Button>
                </>
              }>
              Точно ли хотите удалить чат
            </Alert>
          )}
          <Grid container>
            <Grid item xs={6} md={6} lg={6}>
              <ClientListView
                data={clientList}
                selectedClient={selectedClient != null ? selectedClient : null}
                selectedTopic={selectedTopic}
                onChangeClient={this.onChangeClient}
                onResetClient={this.onResetClient}
                onChangeTopic={this.onChangeTopic}
                onHideClient={this.onHideClient}
                onPinClient={this.onPinClient}
                onUnPinClient={this.onUnPinClient}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <MessageListView
                data={this.state.clientList}
                selectedClient={selectedClient != null ? selectedClient : null}
                selectedTopic={selectedTopic}
                onSend={this.onSend}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledEngineProvider>
    );
  }
}
