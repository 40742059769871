import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { IElement, ITextElement } from '../../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class TextWithPlot extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', borderRadius: 2 }}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 900,
            fontSize: '24px',
            lineHeight: '24px',
            color: '#1B1F3B',
            opacity: 0.6,
            m: 1
          }}>
          .
        </Typography>
        <Typography>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
