import { useMatch, useResolvedPath } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import { Box, LinkProps, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import { getUserInfo } from '../../services';

function NavLink({ children, href, ...props }: LinkProps) {
  const resolved = useResolvedPath(href as string);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    // @ts-ignore
    <Link href={href} {...props} style={{ color: match ? '#000000' : '#000000' }}>
      {children}
    </Link>
  );
}

export const HeaderBar = () => {
  const userInfo = getUserInfo();
  let name = '';
  if (userInfo != null) {
    name += typeof userInfo.name === 'string' && userInfo.name.length > 0 ? userInfo.name[0] : '';
    name += typeof userInfo.surname === 'string' && userInfo.surname.length > 0 ? userInfo.surname[0] : '';
  }
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ borderBottomWidth: '1px', borderColor: '#D9D9D9', borderBottomStyle: 'solid', boxShadow: 'none' }}>
      <Toolbar>
        <Container
          maxWidth="lg"
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src="/img.png" width="25" height="25" />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 700,
                  fontSize: '17px',
                  lineHeight: '36px',
                  alignSelf: 'center',
                  textTransform: 'none',
                  ml: 1,
                  mr: 1
                }}>
                Let's Go
              </Typography>
            </Box>
            <Typography sx={{ color: '#757272', opacity: 0.5, fontSize: '12px', mr: '41px', mt: '5px' }}>
              {process.env.REACT_APP_VERSION}
            </Typography>
            <NavLink underline="none" href="/editor" sx={{ mr: 5 }}>
              Услуги
            </NavLink>
            <NavLink underline="none" href="/chat" sx={{ mr: 5 }}>
              Чат
            </NavLink>
            <NavLink underline="none" href="/localization" sx={{ mr: 5 }}>
              Локализация
            </NavLink>
          </Box>
          <Box
            sx={{
              background: '#F3F6FA',
              width: '40px',
              height: '40px',
              borderRadius: '20px',
              alignContent: 'center',
              textAlign: 'center'
            }}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '36px',
                color: '#1B1F3B',
                opacity: 0.6
              }}>
              {name}
            </Typography>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
