import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import '../../../../maneStyles.css';

interface Props {
  topic: string;
}

export class Header extends PureComponent<Props> {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <Box className="messageListViewHeader">
          <Typography className="topic colorMain">{this.props.topic}</Typography>
        </Box>
      </StyledEngineProvider>
    );
  }
}
