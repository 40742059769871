import { v4 as uuidV4 } from 'uuid';

import { IElement } from '../types';

export const getBodyByType = (type: string): IElement[] => {
  let result: IElement = {
    id: uuidV4(),
    type: '',
    title: null,
    attention: false,
    index: 0,
    text: [],
    children: []
  };
  switch (type) {
    case 'CostBox': {
      result.type = 'CostBox';
      result.text = [
        {
          id: uuidV4(),
          value: '0€',
          type: 'normal'
        }
      ];
      break;
    }
    case 'CollapsibleViewWithTitle': {
      result.type = 'CollapsibleViewWithTitle';
      result.children = [
        {
          id: uuidV4(),
          type: 'Spacing16',
          title: null,
          attention: false,
          index: 0,
          text: [],
          children: []
        }
      ];
      break;
    }
    case 'Base': {
      result.type = 'Base';
      break;
    }
    case 'AttentionBoxWithText': {
      result.type = 'InfoBoxWithText';
      result.attention = true;
      break;
    }
    case 'InfoBoxWithText': {
      result.type = 'InfoBoxWithText';
      result.attention = false;
      break;
    }
    case 'DocBox': {
      result.type = 'DocBox';
      break;
    }
    case 'DocBoxWithText': {
      result.type = 'DocBoxWithText';
      break;
    }
    case 'TextWithPlot': {
      result.type = 'TextWithPlot';
      break;
    }
    case 'TextWithIndex': {
      result.type = 'TextWithIndex';
      break;
    }
    case 'TextWithIndent': {
      result.type = 'TextWithIndent';
      break;
    }
    default: {
      result.type = 'Spacing16';
    }
  }
  return [
    result,
    {
      id: uuidV4(),
      type: 'Spacing16',
      title: null,
      attention: false,
      index: 0,
      text: [],
      children: []
    }
  ];
};
