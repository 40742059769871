import React, { PureComponent } from 'react';
import { CollapsibleViewWithTitle } from '../CollapsibleViewWithTitle';
import { Spacing16 } from '../Spacing16';
import { IElement } from '../../../../types';
import { CostBox } from '../CostBox';
import { Base } from '../Base';
import { DocBox } from '../DocBox';
import { DocBoxWithText } from '../DocBoxWithText';
import { InfoBoxWithText } from '../InfoBoxWithText';
import { AttentionBoxWithText } from '../AttentionBoxWithText';
import { TextWithPlot } from '../TextWithPlot';
import { TextWithIndex } from '../TextWithIndex';
import { TextWithIndent } from '../TextWithIndent';
import { Box } from '@mui/material';

interface Props {
  value: IElement;
  selected: string | null;
  onChange: (value: IElement) => void;
  onClick: (id: string) => void;
}

export class Element extends PureComponent<Props> {
  renderBody() {
    const { value } = this.props;
    switch (value.type) {
      case 'CollapsibleViewWithTitle': {
        return (
          <CollapsibleViewWithTitle
            selected={this.props.selected}
            onClick={this.props.onClick}
            value={value}
            onChange={this.props.onChange}
          />
        );
      }
      case 'CostBox': {
        return <CostBox value={value} onChange={this.props.onChange} />;
      }
      case 'Base': {
        return <Base value={value} onChange={this.props.onChange} />;
      }
      case 'DocBox': {
        return <DocBox value={value} onChange={this.props.onChange} />;
      }
      case 'DocBoxWithText': {
        return <DocBoxWithText value={value} onChange={this.props.onChange} />;
      }
      case 'InfoBoxWithText': {
        if (value.attention) {
          return <AttentionBoxWithText value={value} onChange={this.props.onChange} />;
        }
        return <InfoBoxWithText value={value} onChange={this.props.onChange} />;
      }
      case 'TextWithPlot': {
        return <TextWithPlot value={value} onChange={this.props.onChange} />;
      }
      case 'TextWithIndex': {
        return <TextWithIndex value={value} onChange={this.props.onChange} />;
      }
      case 'TextWithIndent': {
        return <TextWithIndent value={value} onChange={this.props.onChange} />;
      }
      case 'Spacing16': {
        return <Spacing16 />;
      }
      default: {
        return null;
      }
    }
  }

  onClick = () => {
    if (this.props.value.type !== 'CollapsibleViewWithTitle') {
      this.props.onClick(this.props.value.id);
    }
  };

  render() {
    return (
      <Box
        onClick={this.onClick}
        sx={
          this.props.selected === this.props.value.id
            ? { borderWidth: '1px', borderColor: 'red', borderStyle: 'solid' }
            : { borderWidth: '1px', borderColor: 'transparent', borderStyle: 'solid' }
        }>
        {this.renderBody()}
      </Box>
    );
  }
}
