import React, { PureComponent } from 'react';
import { Box, Stack } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { Avatar } from '../Avatar';
import { ClientRow } from '../ClientRow';
import { IClient } from '../../index';
import { Header } from '../TopicListView/components/Header';
import { TopicRow } from '../TopicRow';
import './index.css';

interface Props {
  selectedClient: number | null;
  selectedTopic: string | null;
  data: IClient[];
  onChangeClient: (index: number) => void;
  onResetClient: () => void;
  onChangeTopic: (id: string) => void;
  onHideClient: (index: number) => void;
  onPinClient: (userID: number | null) => void;
  onUnPinClient: (userID: number | null) => void;
}

export class ClientListView extends PureComponent<Props> {
  onClickBack = () => this.props.onResetClient();

  render() {
    const { selectedClient, selectedTopic, data, onChangeClient, onChangeTopic, onHideClient } = this.props;
    const client: IClient | null = selectedClient != null ? data[selectedClient] : null;
    return (
      <StyledEngineProvider injectFirst>
        <Box sx={{ display: 'flex' }}>
          <Box
            className="clientListMessageBox"
            sx={{
              background: selectedClient == null ? '#FFFFFF' : '#F3F6FA',
              width: selectedClient == null ? '100%' : 'auto'
            }}>
            <Stack direction="column">
              {data.map((client, index) => (
                <Avatar
                  key={`avatar-${client.id}`}
                  colorIndex={client.colorIndex}
                  selected={selectedClient === index}
                  name={client.name}
                  onClick={() => onChangeClient(index)}
                />
              ))}
            </Stack>
            {selectedClient == null && (
              <Stack direction="column" flexGrow={2}>
                {data.map((client, index) => (
                  <ClientRow
                    key={`client-${client.id}`}
                    userID={client.userID}
                    deviceID={client.deviceID}
                    name={client.name}
                    topic={client.lastMessage.topic}
                    author={client.lastMessage.author}
                    message={client.lastMessage.value}
                    time={client.lastMessage.time}
                    pin={client.pin}
                    onClick={() => onChangeClient(index)}
                    onPin={() => this.props.onPinClient(client.userID)}
                    onUnPin={() => this.props.onUnPinClient(client.userID)}
                  />
                ))}
              </Stack>
            )}
          </Box>
          {selectedClient != null && client != null && (
            <Box className="selectedChatBox">
              <Header name={client.name} onClick={this.onClickBack} onHide={() => onHideClient(selectedClient)} />
              <Box className="topicRowBox">
                {Object.values(client.topicList).map(topic => (
                  <TopicRow
                    key={`topic-${topic.id}`}
                    selected={topic.id === selectedTopic}
                    topic={topic.name}
                    author={topic.data[topic.data.length - 1].author}
                    message={topic.data[topic.data.length - 1].value}
                    time={topic.data[topic.data.length - 1].time}
                    onClick={() => onChangeTopic(topic.id)}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </StyledEngineProvider>
    );
  }
}
