import React, { PureComponent } from 'react';
import { Box, TextField } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

interface State {
  value: string;
}

export class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onChange = (value: string) => {
    this.props.onChange(value);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props !== prevProps) {
      this.setState(() => ({
        value: this.props.value
      }));
    }
  }

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          multiline
          onChange={event => this.onChange(event.target.value)}
          value={this.state.value}
          fullWidth
          id="title"
          variant="outlined"
          size="small"
          placeholder="Header"
        />
      </Box>
    );
  }
}
