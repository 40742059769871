import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { Avatar } from '../Avatar';
import './index.css';

interface Props {
  colorIndex: number;
  author: string;
  message: string;
  created: string;
  root: boolean;
}

export class Message extends PureComponent<Props> {
  render() {
    const { root } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box
          className="mainMessageBox"
          sx={{
            justifyContent: root ? 'flex-end' : 'flex-start',
            ml: root ? 0 : 1,
            mr: root ? 1 : 0
          }}>
          {!root && <Avatar colorIndex={this.props.colorIndex} name={this.props.author} />}
          <Box className="messageBox" sx={{ ml: root ? 0 : 1, background: root ? '#343434' : '#FFFFFF' }}>
            <Typography className="message" sx={{ color: root ? '#FFFFFF' : '#1B1F3B' }}>
              {this.props.message}
            </Typography>
            <Typography className="created" sx={{ color: root ? '#FFFFFF' : '#1B1F3B' }}>
              {this.props.created}
            </Typography>
          </Box>
        </Box>
      </StyledEngineProvider>
    );
  }
}
