import React, { PureComponent } from 'react';
import { Box, TextField } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IElement } from '../../../../types';
import { Element } from '../Element';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  value: IElement;
  selected: string | null;
  onChange: (value: IElement) => void;
  onClick: (id: string) => void;
}

interface State {
  value: string;
  collapsed: boolean;
}

export class CollapsibleViewWithTitle extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value.title || '',
      collapsed: false
    };
  }

  onChangeTitle = (value: string) => {
    this.props.onChange({
      ...this.props.value,
      title: value
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props !== prevProps) {
      this.setState(() => ({
        value: this.props.value.title || ''
      }));
    }
  }

  onChangeChildren = (index: number, value: IElement) => {
    // @ts-ignore
    const result: IElement = { ...this.props.value };
    result.children.splice(index, 1, value);

    this.props.onChange(result);
  };

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box
          sx={
            this.props.selected === this.props.value.id
              ? {
                  borderWidth: '1px',
                  borderColor: 'red',
                  borderStyle: 'solid',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }
              : {
                  borderWidth: '1px',
                  borderColor: 'transparent',
                  borderStyle: 'solid',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }
          }>
          <TextField
            onClick={() => this.props.onClick(this.props.value.id)}
            onChange={event => this.onChangeTitle(event.target.value)}
            multiline
            value={this.state.value}
            fullWidth
            id="title"
            variant="outlined"
            size="small"
            placeholder="Title"
          />
          {this.state.collapsed ? (
            <ArrowDropDownIcon onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))} />
          ) : (
            <ArrowDropUpIcon onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))} />
          )}
        </Box>
        <Box sx={{ marginLeft: 2 }}>
          {!this.state.collapsed &&
            this.props.value.children.map((elem, index) => (
              <Element
                onClick={this.props.onClick}
                selected={this.props.selected}
                value={elem}
                onChange={value => this.onChangeChildren(index, value)}
              />
            ))}
        </Box>
      </Box>
    );
  }
}
