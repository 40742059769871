import React, { PureComponent } from 'react';
import { Box, TextField } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IElement } from '../../../../types';

interface Props {
  value: IElement;
  onChange: (value: IElement) => void;
}

interface State {
  value: string;
}

export class DocBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value.title || ''
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props !== prevProps) {
      this.setState(() => ({
        value: this.props.value.title || ''
      }));
    }
  }

  onChange = (value: string) => {
    this.props.onChange({
      ...this.props.value,
      title: value
    });
  };

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            width: 40,
            height: 40,
            background: '#F3F6FA',
            borderRadius: 5,
            marginRight: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <DescriptionOutlinedIcon />
        </Box>
        <TextField
          onChange={event => this.onChange(event.target.value)}
          multiline
          value={this.state.value}
          fullWidth
          id="title"
          variant="outlined"
          size="small"
          placeholder="Title"
        />
      </Box>
    );
  }
}
