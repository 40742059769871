import React, { PureComponent } from 'react';
import { Box, TextField } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IElement, TextElement } from '../../../../types';
import { v4 as uuidV4 } from 'uuid';

interface Props {
  value: IElement;
  onChange: (value: IElement) => void;
}

interface State {
  title: string;
  value: string;
}

export class DocBoxWithText extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      title: this.props.value.title || '',
      value: this.props.value.text
        .map(element => (element.type === 'bold' ? `<bold>${element.value}</bold>` : element.value))
        .toString()
    };
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props !== prevProps) {
      this.setState(() => ({
        title: this.props.value.title || '',
        value: this.props.value.text
          .map(element => (element.type === 'bold' ? `<bold>${element.value}</bold>` : element.value))
          .toString()
      }));
    }
  }

  onChangeTitle = (value: string) => {
    this.props.onChange({
      ...this.props.value,
      title: value
    });
  };

  onChange = (value: string) => {
    this.props.onChange({
      ...this.props.value,
      text: [new TextElement(uuidV4(), value)]
    });
  };

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              width: 40,
              height: 40,
              background: '#F3F6FA',
              borderRadius: 5,
              marginRight: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <DescriptionOutlinedIcon />
          </Box>
          <TextField
            onChange={event => this.onChangeTitle(event.target.value)}
            value={this.state.title}
            multiline
            fullWidth
            id="title"
            variant="outlined"
            size="small"
            placeholder="Title"
          />
        </Box>
        <TextField
          onChange={event => this.onChange(event.target.value)}
          value={this.state.value}
          multiline
          id="title"
          variant="outlined"
          size="small"
          placeholder="Body"
        />
      </Box>
    );
  }
}
