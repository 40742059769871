import React, { PureComponent } from 'react';
import { Box, Container, Typography } from '@mui/material';

export class Forbidden extends PureComponent {
  render() {
    return (
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '15%' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '28px',
              alignSelf: 'center',
              color: '#1B1F3B',
              opacity: 0.6,
              mb: 3
            }}>
            Вам здесь не рады
          </Typography>
          <img src="https://i.pinimg.com/564x/86/39/27/8639278ec8e10d8b5d2f5d5c316c6475.jpg" />
        </Box>
      </Container>
    );
  }
}
