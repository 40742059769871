import React, { Component } from 'react';
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  TextField,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BodyCell, HeaderCell } from '../editor';
import { axiosInstance } from '../app';
import env from '../../environment.json';
import { removeAuthToken } from '../../services';

interface Props {}

interface State {
  key: string;
  ru: string;
  data: {
    key: string;
    value: {
      ru: string;
      en: string;
      es: string;
    };
  }[];
}

export class Localization extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      key: '',
      ru: '',
      data: []
    };
  }

  componentDidMount() {
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/localization`)
      .then(response => this.setState(() => ({ data: response.data.words })));
  }

  onSave() {
    axiosInstance
      // @ts-ignore
      .post(`${env[env.environment].host}/v1/localization`, { words: [...this.state.data] })
      .catch(() => {
        removeAuthToken();
        window.location.reload();
      });
  }

  onAdd = () =>
    this.setState(prev => ({
      data: [
        ...prev.data,
        {
          key: prev.key,
          value: {
            ru: prev.ru,
            en: '',
            es: ''
          }
        }
      ],
      ru: ''
    }));

  onChange = (value: string, index: number, lang: string) =>
    this.setState(prev => ({
      data: [
        ...prev.data.slice(0, index),
        {
          ...prev.data[index],
          value: {
            ...prev.data[index].value,
            [lang]: value
          }
        },
        ...prev.data.slice(index + 1)
      ]
    }));

  onDelete = (index: number) =>
    this.setState(prev => ({
      data: [...prev.data.slice(0, index), ...prev.data.slice(index + 1)]
    }));

  render() {
    return (
      <Container maxWidth={false} sx={{ maxHeight: '100vh' }}>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', mt: '20px' }}>
          <Box sx={{ display: 'flex' }}>
            <Input
              value={this.state.key}
              onChange={event => this.setState(() => ({ key: event.target.value }))}
              disableUnderline
              placeholder="ключ"
              sx={{ border: '1px solid black', borderRadius: '7px', mr: '10px' }}
            />
            <Input
              value={this.state.ru}
              onChange={event => this.setState(() => ({ ru: event.target.value }))}
              disableUnderline
              placeholder="значение на русском"
              sx={{ border: '1px solid black', borderRadius: '7px', mr: '10px' }}
            />
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              sx={{ height: '100%' }}
              onClick={this.onAdd}>
              Добавить
            </Button>
          </Box>
          <Button variant="contained" sx={{ background: 'green', ml: '30px' }} onClick={() => this.onSave()}>
            Сохранить изменения
          </Button>
        </Box>
        <TableContainer
          sx={{
            border: '1px solid black',
            mt: '35px',
            maxHeight: 'calc(100vh - 190px)',
            minHeight: 'calc(100vh - 190px)',
            overflow: 'auto',
            p: '0 0 0 10px'
          }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: +1
              }}>
              <TableRow sx={{ borderBottom: '1px solid black' }}>
                <HeaderCell width="16%" align="left">
                  Ключ
                </HeaderCell>
                <HeaderCell width="20%" align="left">
                  RU
                </HeaderCell>
                <HeaderCell width="20%" align="left">
                  EN
                </HeaderCell>
                <HeaderCell width="20%" align="left">
                  ES
                </HeaderCell>
                <HeaderCell width="4%" align="left"></HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: '100% -56.8px ' }}>
              {this.state.data.map((word, index) => (
                <TableRow sx={{ backgroundColor: 'white' }} key={word.key}>
                  <BodyCell style={{ padding: '0 0 0 10px' }} align="left">
                    {word.key}
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    <TextField
                      value={word.value.ru}
                      multiline
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onChange={event => this.onChange(event.target.value, index, 'ru')}
                      sx={{ width: '100%', p: 0 }}></TextField>
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    <TextField
                      value={word.value.en}
                      multiline
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onChange={event => this.onChange(event.target.value, index, 'en')}
                      sx={{ width: '100%', p: 0 }}></TextField>
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    <TextField
                      value={word.value.es}
                      multiline
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onChange={event => this.onChange(event.target.value, index, 'es')}
                      sx={{ width: '100%', p: 0 }}></TextField>
                  </BodyCell>
                  <BodyCell align="left">
                    <IconButton disableRipple sx={{ color: '#db0d0d' }} onClick={() => this.onDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
