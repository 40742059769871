import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  value: string;
}

export class Header extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography>{this.props.value}</Typography>
      </Box>
    );
  }
}
