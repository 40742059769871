import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { IElement, ITextElement } from '../../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class CostBox extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', background: '#F3F6FA' }}>
        <Typography>Стоимость услуги</Typography>
        <Typography>
          {' '}
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
