import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';

export const withNavigateParamsHooksHOC = (Component: any) => (props: any) => {
  const navigate = useNavigate();
  const params = useParams();

  return <Component navigate={navigate} params={params} {...props} />;
};

export const withNavigateHookHOC = (Component: any) => (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  return <Component navigate={navigate} location={location} {...props} />;
};
