import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { cAvatarColorList } from '../../../Avatar';
import './index.css';

interface Props {
  colorIndex: number;
  name: string;
}

export class Avatar extends PureComponent<Props> {
  render() {
    const { colorIndex, name } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box className="avatarBox" sx={{ background: `${cAvatarColorList[colorIndex]}` }}>
          <Typography className="name">{name[0]}</Typography>
        </Box>
      </StyledEngineProvider>
    );
  }
}
