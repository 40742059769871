import React, { PureComponent } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import { setAuthToken, setUserInfo } from '../../services';
import { withNavigateHookHOC } from '../hook';
import { axiosInstance } from '../app';
import env from '../../environment.json';

interface InjectedProps {
  navigate: (_path: string, _p: { replace: boolean }) => void;
}

interface Props {}

class LoginConnect extends PureComponent<Props> {
  get injected(): InjectedProps {
    return this.props as Props & InjectedProps;
  }

  onSuccess = ({ credential }: any) => {
    axios
      // @ts-ignore
      .post(`${env[env.environment].host}/v1/authorization/google`, { token: credential })
      .then(({ data }) => {
        setAuthToken(data.token);
        setUserInfo(data.payload);
        this.injected.navigate('/', {
          replace: true
        });
        axiosInstance.defaults.headers.common.Authorization = `token=${data.token}`;
      })
      .catch(error => {
        console.log(error);
        this.injected.navigate('/403', {
          replace: true
        });
      });
  };

  render() {
    return (
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '25%' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '28px',
              alignSelf: 'center',
              color: '#1B1F3B',
              opacity: 0.6,
              mb: 3
            }}>
            Вход в аккаунт
          </Typography>
          <GoogleLogin
            onSuccess={this.onSuccess}
            onError={() => {
              alert('Ошибка работы с Google');
            }}
            useOneTap
          />
        </Box>
      </Container>
    );
  }
}

export const Login = withNavigateHookHOC(LoginConnect);
