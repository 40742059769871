import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';

import { getAuthToken } from '../../services';

// eslint-disable-next-line no-undef
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const token = getAuthToken();

  if (token == null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
