import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IElement } from '../../../../types';

interface Props {
  value: IElement;
}

export class DocBox extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          sx={{
            display: 'flex',
            width: 40,
            height: 40,
            background: '#F3F6FA',
            borderRadius: 5,
            marginRight: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <DescriptionOutlinedIcon />
        </Box>
        <Typography>{this.props.value.title}</Typography>
      </Box>
    );
  }
}
