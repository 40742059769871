export const getAuthToken = (): null | string => window.localStorage.getItem('letsgoglobal-token');

export const setAuthToken = (token: string) => window.localStorage.setItem('letsgoglobal-token', token);

export const removeAuthToken = () => window.localStorage.removeItem('letsgoglobal-token');

export const setUserInfo = (payload: object) =>
  window.localStorage.setItem('letsgoglobal-user', JSON.stringify(payload));

export const getUserInfo = () => {
  const info = window.localStorage.getItem('letsgoglobal-user');
  return info != null ? JSON.parse(info) : null;
};
