import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '24px',
          color: '#1B1F3B',
          opacity: 0.65
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '24px',
          color: '#1B1F3B',
          opacity: 0.65,
          padding: 8
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '24px',
          color: '#1B1F3B',
          opacity: 0.65,
          padding: 8
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          ':hover': {
            background: 'none'
          }
        }
      }
    }
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       border: '1px solid black'
    //     }
    //   }
    // }
  }
});
