import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IElement, ITextElement } from '../../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class DocBoxWithText extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              display: 'flex',
              width: 40,
              height: 40,
              background: '#F3F6FA',
              borderRadius: 5,
              marginRight: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <DescriptionOutlinedIcon />
          </Box>
          <Typography>{this.props.value.title}</Typography>
        </Box>
        <Typography>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
