import React, { PureComponent } from 'react';
import { Box } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';

interface Props {
  name: string;
}

export class TopicIcon extends PureComponent<Props> {
  render() {
    const { name } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box className="topicIcon">{name.length > 0 && name[0]}</Box>
      </StyledEngineProvider>
    );
  }
}
