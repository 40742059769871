import React, { Component } from 'react';
import { v4 as uuidV4 } from 'uuid';
import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { IService } from '../types';
import { DateTime } from 'luxon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import env from '../../environment.json';
import { axiosInstance } from '../app';
import { removeAuthToken } from '../../services';
import { AxiosError } from 'axios';

export const HeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.65,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 16,
    paddingBottom: 16
  }
}));

export const BodyCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.8,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 16,
    paddingBottom: 16
  }
}));

interface Props {}

interface State {
  data: IService[];
}

export class Editor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/service/list/short?country=spain&language=ru`)
      .then(response => this.setState(() => ({ data: response.data })))
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  }

  onAddNew() {
    const data = {
      id: uuidV4(),
      categoryID: 1,
      category: 'Банковские услуги',
      icon: 'card',
      name: 'noname',
      topic: 'spain-9999',
      header: 'noname',
      children: [
        {
          id: uuidV4,
          text: [],
          type: 'Spacing16',
          index: 0,
          title: null,
          children: [],
          attention: false
        }
      ]
    };
    axiosInstance
      // @ts-ignore
      .post(`${env[env.environment].host}/v1/service?language=ru`, data)
      // @ts-ignore
      .then(() => axiosInstance.get(`${env[env.environment].host}/v1/service/list/short?country=spain`))
      .then(response => this.setState(() => ({ data: response.data })))
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  }

  render() {
    return (
      <Container
        style={{
          padding: 0
        }}>
        <Box sx={{ display: 'flex', flex: 1, m: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={() => this.onAddNew()}>
            Создать
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCell width="35%" align="left">
                  Название
                </HeaderCell>
                <HeaderCell width="15%" align="left">
                  Категория
                </HeaderCell>
                <HeaderCell width="10%" align="left">
                  Страна
                </HeaderCell>
                <HeaderCell width="25%" align="left">
                  Последнее редактирование
                </HeaderCell>
                <HeaderCell align="left"></HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map(row => (
                <TableRow>
                  <BodyCell align="left">{row.name}</BodyCell>
                  <BodyCell align="left">{row.category}</BodyCell>
                  <BodyCell align="left">{row.country}</BodyCell>
                  <BodyCell align="left">
                    {row.manager}&nbsp;{DateTime.fromSQL(row.updated).toFormat('dd.MM.yyyy')}
                  </BodyCell>
                  <BodyCell align="left">
                    <Stack direction="row" spacing={2}>
                      <Button
                        href={`/editor/service/${row.id}`}
                        sx={{
                          fontFamily: 'Roboto',
                          fontWeight: 400,
                          fontSize: '17px',
                          lineHeight: '24px',
                          textTransform: 'none'
                        }}>
                        Редактировать
                      </Button>
                      <Button
                        sx={{
                          fontFamily: 'Roboto',
                          fontWeight: 400,
                          fontSize: '17px',
                          lineHeight: '24px',
                          textTransform: 'none'
                        }}>
                        Скрыть
                      </Button>
                    </Stack>
                  </BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
