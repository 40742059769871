import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IElement, ITextElement } from '../../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class InfoBoxWithText extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', background: 'rgba(112, 182, 246, 0.12)', borderRadius: 2 }}>
        <InfoOutlinedIcon sx={{ m: 1, color: '#526ED3' }} />
        <Typography sx={{ whiteSpace: 'pre-line' }}>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
