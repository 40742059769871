import React, { PureComponent } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { cTopicAliasToName } from '../../index';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import '../../maneStyles.css';

interface Props {
  userID: number | null;
  deviceID: string | null;
  name: string | null;
  topic: string;
  author: string;
  message: string;
  time: string;
  pin: number;
  onClick: () => void;
  onPin: () => void;
  onUnPin: () => void;
}

interface State {}

export class ClientRow extends PureComponent<Props, State> {
  render() {
    const { userID, deviceID, name, topic, author, message, time } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box className="clientRowBox">
          <Box className="hover" onClick={this.props.onClick}>
            <Box className="userIdBox">
              <Typography className="nameText">{name}</Typography>
              &nbsp;
              <Typography className="userIdText">
                {userID != null ? `userID:${userID}` : `deviceID:${deviceID}`}
              </Typography>
            </Box>
            <Typography className="topicText">{`${cTopicAliasToName[topic]}(${topic})`}</Typography>
            <Box className="ClientRowMessageBox">
              <Typography className="text15 colorBlue authorText">{author}:&nbsp;</Typography>
              <Typography className="text15 colorMain clientRowTextMessage">{message}</Typography>
            </Box>
          </Box>
          <Box className="boxTime">
            <Typography className="text15 colorMain">{time}</Typography>
            <IconButton
              onClick={() => (this.props.pin === 0 ? this.props.onPin() : this.props.onUnPin())}
              disableRipple
              sx={{ m: '15px 0 0 3px' }}>
              <Box
                className="pin"
                sx={{ backgroundImage: this.props.pin === 0 ? 'url(pin-white.png)' : 'url(pin-black.png)' }}></Box>
            </IconButton>
          </Box>
        </Box>
      </StyledEngineProvider>
    );
  }
}
