import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';

export const UnauthorizedHeaderBar = () => {
  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Container
          maxWidth="lg"
          sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src="img.png" width="25" height="25" />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 700,
                  fontSize: '17px',
                  lineHeight: '36px',
                  alignSelf: 'center',
                  textTransform: 'none',
                  ml: 1,
                  mr: 5
                }}>
                Let's Go
              </Typography>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
