import React, { PureComponent } from 'react';
import { Box, TextField } from '@mui/material';
import { IElement, TextElement } from '../../../../types';
import { v4 as uuidV4 } from 'uuid';

interface Props {
  value: IElement;
  onChange: (value: IElement) => void;
}

interface State {
  value: string;
}

export class TextWithIndent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value.text
        .map(element => (element.type === 'bold' ? `<bold>${element.value}</bold>` : element.value))
        .toString()
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props !== prevProps) {
      this.setState(() => ({
        value: this.props.value.text
          .map(element => (element.type === 'bold' ? `<bold>${element.value}</bold>` : element.value))
          .toString()
      }));
    }
  }

  onChange = (value: string) => {
    this.props.onChange({
      ...this.props.value,
      text: [new TextElement(uuidV4(), value)]
    });
  };

  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', borderRadius: 2 }}>
        <Box
          sx={{
            m: 1
          }}></Box>
        <TextField
          onChange={event => this.onChange(event.target.value)}
          value={this.state.value}
          fullWidth
          multiline
          id="title"
          variant="outlined"
          size="small"
          placeholder="Body"
        />
      </Box>
    );
  }
}
